import React from 'react';
import styled from 'styled-components';
import { Label } from '../../components/atoms/Label';
import { Service_organisms } from '../../components/organisms/Service_organisms';
import { IndustrialDesign_organisms } from '../../components/organisms/IndustrialDesign_organisms';

export const Services = () => {
  return (
    <ContainerService>
      <Section>
        <Label label="Servicios" colorl="#09333F" sizeText="30" />
        <Label sizeText="14" label="Realizamos servicio de mantenimiento técnico industrial a carretas y otros productos de metal, tales como:" />
        <Service_organisms />
      </Section>
      <Section>
        <Label label="Diseño industrial" colorl="#09333F" sizeText="30" />
        <Label sizeText="14" label="Contamos con personal idóneo para desarrollo de proyectos específicos; diseño variado de tráileres y carretas según su necesidad de carga a movilizar. Ejemplos de diseños personalizados:" />
        <IndustrialDesign_organisms />
      </Section>
    </ContainerService>
  );
};

const ContainerService = styled.div`
  display: grid;
  row-gap: 40px;
  column-gap: 34px;
  margin-bottom: 50px;
  width: 90%;
  margin: 0 auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
`;
