import React from 'react';
import styled from 'styled-components';

type Props = {
  texto: string;
  active: boolean;
  selectText: React.Dispatch<React.SetStateAction<string>>;
};

export const Button = ({ texto, active, selectText }: Props) => {
  return (
    <ContainerCatalogue>
      <ButtonToggleCatalogue actives={active} onClick={() => selectText(texto)}>
        {texto}
      </ButtonToggleCatalogue>
    </ContainerCatalogue>
  );
};

// Estilo del contenedor del botón
const ContainerCatalogue = styled.div`
  display: flex;
  width: 100%;
  background-color: #DC2E31;
  height: fit-content;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 0;
  }
`;

// Estilo del botón con control de estados activo y normal
const ButtonToggleCatalogue = styled.button<{ actives: boolean }>`
  opacity: 0.9;
  background-color: ${({ actives }) => (actives ? 'white' : '#DC2E31')};
  color: ${({ actives }) => (actives ? '#DC2E31' : 'white')};
  border: none;
  padding: 10px 20px;
  /* text-transform: uppercase; */
  font-weight: 700;
  cursor: pointer;
  width: 100%; /* Ancho completo para mobile */
  text-align: start;
  transition: background-color 250ms ease, color 250ms ease;
  margin: 5px 0;

  &:hover {
    background-color: white;
    color: #DC2E31;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  /* Estilo responsivo */
  @media (max-width: 768px) {
    width: auto; /* Adaptable para mobile */
    text-align: center;
    padding: 10px;
    font-size: 16px;
  }
`;
