import globalStyles from '../../../globalStyles.module.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Label } from '../Label';
import json from '../../../assets/productos.json'

type Props = {
  image?: string;
  texto?: string;
  open: React.Dispatch<React.SetStateAction<boolean>>;
  option: React.Dispatch<React.SetStateAction<{
    name: string;
    image: string;
    specifications: string;
    dimension: string;
    uses: string;
}[]>>;
};

export const Producto = ({ image, texto, option, open }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <>
      <ContainerLabel>
        <Imagen src={image}></Imagen>
        <Label label={texto} sizeText='12' fontType='700' textAlight='left'/>
        <Button onClick={() =>{option(json.data.filter(element => element.name===texto));open(true)}}>Leer mas</Button>
      </ContainerLabel>
    </>
  );
};

const ContainerLabel = styled.div`
  display: flex;
  flex-flow: column;
  width: 170px;
  /* height: 203px; */
  background: rgb(217, 217, 217);
  padding: 5px;
`;

const Imagen = styled.img`
  margin-bottom: 10px;
`;

const Button = styled.button`
  margin-top: 10px;
  background-color: #DC2E31;
  color: white;
  border: none;
  padding: 5px;
  width: fit-content;
`;