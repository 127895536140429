import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import image from '../../assets/Rectangle4.png'
import { Label } from '../../components/atoms/Label';
import { SliderP } from '../../components/atoms/slider';

import image2 from '../../assets/sliderP/Rectangle327.png';
import image3 from '../../assets/sliderP/Rectangle5.png';
import image4 from '../../assets/sliderP/Tenemos.png';
import { FacebookPagePlugin } from '../../components/atoms/FacebookPagePlugin';

type Props = {

};

export const Principal = ({ }: Props) => {
  return (
    <ContainerGen style={{ marginTop: '32px' }}>
  <ContainerLabel>
    <ImageContainer>
      <SliderP images={[{ src: image4 }, { src: image3 }, { src: image2 }]} />
    </ImageContainer>

    {/* 🧩 Nueva fila con dos columnas */}
    <MapRow>
      <MapText>
        <Label
          label="Hacemos tu vida mas facil porque las herramientas que creamos te ahorraran esfuerzo fisico: las cargas se haran mas livianas, las distancias mas cortas y se optimizan los procesos y recursos."
          sizeText="16"
          colorl="#333"
          fontType="400"
          textAlight='left'
        />
      </MapText>

      <MapContent>
        <FacebookPagePlugin pageUrl="https://www.facebook.com/TecnicarretilasDeColombia" />
      </MapContent>
    </MapRow>
  </ContainerLabel>
</ContainerGen>

  );
};


const ContainerGen = styled.div`
  max-width: 1200px;
  @media (max-width: 768px) {
    padding: 20px;
    max-width: 80%;
  }
`;

const ContainerLabel = styled.div`
  display: flex
  flex-direction: row;
  row-gap: 26px;
  column-gap: 34px;
  margin-top: 26px;
  margin-bottom: 50px;
`;

const Labels = styled.label`
  font-size: 14px;
`;

const MapRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MapText = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  align-self: center;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const MapContent = styled.div`
  flex: 1;
  max-width: 600px;
  padding: 20px;
  background-color: #09333F;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.01);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    align-self: center;
  }
`;

const ImageContainer = styled.div`
  background-image: url(${image});
  margin-bottom: 30px;
`;