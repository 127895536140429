import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProductoCategoria } from '../../components/atoms/ProductoCategoria';
import image from '../../assets/paginaRecurso15.png'
import { Label } from '../../components/atoms/Label';

type Props = {

};

export const Category = ({ }: Props) => {
  return (
    <div style={{marginTop:'32px'}}>
    <Label label='Tenemos un producto para cada necesidad' sizeText='15' fontType='500'/>
      <ContainerLabel>
        <ProductoCategoria image={image} texto='Requieres movilizar cajas?'/>
        <ProductoCategoria image={image} texto='Requieres movilizar canecas?'/>
        <ProductoCategoria image={image} texto='Requieres movilizar canastas?'/>
        <ProductoCategoria image={image} texto='Requieres movilizar cilindros?'/>
        <ProductoCategoria image={image} texto='Requieres subir o bajar productos por la escalera?'/>
        <ProductoCategoria image={image} texto='Requieres movilizar bultos?'/>
        <ProductoCategoria image={image} texto='Requieres movilizar y guardar herramientas?'/>
        <ProductoCategoria image={image} texto='Requieres levantar la rueda de tu vehículo?'/>
        <ProductoCategoria image={image} texto='Requieres movilizar plantas eléctricas e hidrolavadoras?'/>
        <ProductoCategoria image={image} texto='Requieres hacer recolección de basuras?'/>
        <ProductoCategoria image={image} texto='Requieres alcanzar lugares altos?'/>
        <ProductoCategoria image={image} texto='Requieres soportes para plataformas?'/>
        <ProductoCategoria image={image} texto='Requieres exhibir avisos?'/>
        <ProductoCategoria image={image} texto='Requieres exhibir avisos?'/>
      </ContainerLabel>
    </div>
  );
};

const ContainerLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 26px;
  column-gap: 34px;
  margin-top: 26px;
  margin-bottom: 50px;
`;

const Labels = styled.label`
  font-size: 14px;
`;