import React, { useState } from 'react';
import styled from 'styled-components';
import { Label } from '../../atoms/Label';
import logo from '../../../assets/iconologoRecurso2.png';
import what from '../../../assets/paginaRecurso5.png';
import cell from '../../../assets/paginaRecurso9.png';
import map from '../../../assets/paginaRecurso8.png';
import insta from '../../../assets/redesRecurso30.png';
import face from '../../../assets/redesRecurso31.png';
import yout from '../../../assets/redesRecurso32.png';
import { FooterText } from '../../molecules/FooterText';

type Props = {
  texto?: string;
  image?: string;
};

export const Footer = ({ texto, image }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <FooterOuter>
      <FooterContainer>
        <SectionOne>
          <Image src={logo} alt="Logo"/>
        </SectionOne>
        <SectionTwo>
          <Label label='Contáctenos' colorl={'white'} fontType="600"/><br></br>
          <Label label='Horario de atención' colorl={'white'} fontType="500"/>
          <Label label='Lunes a viernes: 7:30 am a 4:30 pm' colorl={'white'} fontType="400"/>
          <Label label='Sábado: 7:30 am a 12:30 pm' colorl={'white'} fontType="400"/><br></br>
          <Label label='tecnicarretillas@hotmail.com' colorl={'white'} fontType="400"/>
          <Label label='tecnicarretillas@gmail.com' colorl={'white'} fontType="400"/>
        </SectionTwo>
        <SectionThree>
          <Label label='¿Requieres más información?' colorl={'white'} fontType="600"/><br></br>
          <FooterText image={cell} texto='+57 601 6047378' link='tel:+576016047378'/>
          <FooterText image={what} texto='Chatea con nosotros' link='https://api.whatsapp.com/send?phone=573156012396&text=Hola! deseo asesoria:'/><br></br>
          <FooterText image={map} texto='Calle 5a  #86a - 27 Barrio Tayrona, Bogotá D.C.'/>
        </SectionThree>
        <SectionFour>
          <Label label='Síguenos en nuestras redes' colorl={'white'} fontType="600"/><br></br>
          <Social>
            <FooterText image={insta} social={true} link='https://www.instagram.com/tecnicarretillas?igsh=ZnM1aHk5MGlyM3Ny'/>
            <FooterText image={face} social={true} link='https://www.facebook.com/TecnicarretilasDeColombia'/>
            <FooterText image={yout} social={true} link='https://www.youtube.com/@tecnicarretillas7028/videos'/>
          </Social>
        </SectionFour>
      </FooterContainer>
    </FooterOuter>
  );
};

// Estilos mejorados para el footer
const FooterOuter = styled.div`
  background-color: #09333F;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const FooterContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1600px;
  grid-template-columns: repeat(4, 1fr);
  padding: 40px 0px;
  color: white;
  row-gap: 20px;
  column-gap: 30px;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const SectionOne = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const SectionThree = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const SectionFour = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Social = styled.div`
  display: flex;
  gap: 5px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Image = styled.img`
  max-width: 100px;
  height: auto;

  @media (max-width: 768px) {
    max-width: 80px;
  }
`;
