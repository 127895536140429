import React from 'react';
import styled from 'styled-components';
import image from '../../assets/Rectangle364.png';
import { Label } from '../../components/atoms/Label';
import ContactForm from '../../components/organisms/Form';

export const Contact = () => {
  return (
    <Container>
      <TopRow>
        <LeftColumn>
          <Label label="Servicio al cliente" colorl="#09333F" sizeText="30" />
          <InfoContent>
            <Label sizeText="15" fontType="700" label="Si requieres comunicarte con nosotros" />
            <Label sizeText="14" label="Llámanos al teléfono: +57 601 6047378" />
          </InfoContent>
          <InfoContent>
            <Label sizeText="15" fontType="700" label="Si requieres ubicarnos" />
            <Label sizeText="14" label="Ubícanos en Bogotá en la Calle 5a #86a - 27" />
          </InfoContent>
          <InfoContent>
            <Label sizeText="15" fontType="700" label="Si requieres asesoría o indicaciones de un producto" />
            <Label sizeText="14" label="Escríbenos al WhatsApp" />
          </InfoContent>
        </LeftColumn>

        <RightColumn>
        <ImagenContact src={image} alt="Fachada" />
        </RightColumn>
      </TopRow>

      <BottomRow>
        <FullWidthSection>
          <FormContainer>
            <ContactForm />
          </FormContainer>
        </FullWidthSection>

        <FullWidthSection>
          <MapContent>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.739477191186!2d-74.16007872443603!3d4.640496442175323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9c2f8d032c53%3A0xe27891bc401186ed!2sTecniCarretillas!5e0!3m2!1ses!2sco!4v1684145875677!5m2!1ses!2sco"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </MapContent>
        </FullWidthSection>
      </BottomRow>
    </Container>
  );
};

// Estilos generales
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TopRow = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightColumn = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const FullWidthSection = styled.div`
  width: 100%;
`;

const ImagenContact = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  /* background-color: #f9f9f9; */
  padding: 30px;
  border-radius: 10px;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08); */
`;

const MapContent = styled.div`
  border-radius: 10px;
  overflow: hidden;
  height: 450px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
`;
