import { useEffect, useRef, useState } from "react";
import { Input } from "../../atoms/Input";
import emailjs from '@emailjs/browser'
import { Label } from "../../atoms/Label";
import styled from "styled-components";

const ContactForm: React.FC = () => {
    const [name, setName] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [focusN, setOnFocusN] = useState('Nombre');
    const [focusC, setOnFocusC] = useState('Teléfono');
    const [focusE, setOnFocusE] = useState('Email');
    const [focusM, setOnFocusM] = useState('Mensaje');

  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      const serviceID = 'service_tecnicarretillas';
      const templateID = 'template_tecnicarretilla';
      const userID = '66gpFHSCwy8EuPAi7';

      // Set up the email parameters
    const templateParams = {
        name: name,
        email: email,
        message: message,
        cellphone: cellphone
      };
      if(name!=''&&cellphone!=''&&email!=''&&message!=''){
        // Send the email using EmailJS
        emailjs.send(serviceID, templateID, templateParams, userID)
        .then((response) => {
        // Reset the form fields
        setName('');
        setEmail('');
        setMessage('');
        })
        .catch((error) => {
        });

        // Reset the form fields
        setName('');
        setCellphone('');
        setEmail('');
        setMessage('');
      }
    
    };
  
    return (
        <Container>
            <ContainerLeft>
                <Label label={'Escríbenos un mensaje'} sizeText="16" colorl="black" fontType="700"></Label>
            </ContainerLeft>
            <ContainerRight>
                <form style={{display:'grid', gridTemplateColumns:'1fr 0.2fr', gap: '20px'}} id="contactForm" onSubmit={handleSubmit}>
                    <div>
                        <div style={{display:'grid', marginBottom:'10px'}}>
                        <Label label={focusN} sizeText="12" colorl="black" form={true}></Label>
                        <Input setOnFocus={setOnFocusN} type="text" id="name" value={name} setProperty={setName}></Input>
                        </div>
                        <div style={{display:'grid', marginBottom:'10px'}}>
                        <Label label={focusC} sizeText="12" colorl="black" form={true}></Label>
                        <Input setOnFocus={setOnFocusC} type="number" id="cellphone" value={cellphone} setProperty={setCellphone}></Input>
                        </div>
                        <div style={{display:'grid'}}>
                        <Label label={focusE} sizeText="12" colorl="black" form={true}></Label>
                        <Input setOnFocus={setOnFocusE} type="email" id="email" value={email} setProperty={setEmail}></Input>
                        </div>
                        <div style={{display:'grid'}}>
                        <Label label={focusM} sizeText="12" colorl="black" form={true}></Label>
                        <textarea
                        style={{backgroundColor: '#D9D9D9',width: '100%' , border: 'none', height:'96px', marginTop:'10px'}}
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onFocus={(e) => setOnFocusM('')}
                        />
                        </div>
                    </div>
                    <button style={{width:'72px', height:'44px', border:'none', backgroundColor:'#09333F', color:'white', alignSelf:'end'}} type="submit">Submit</button>
                </form>
            </ContainerRight>
        </Container>
      
    );
  };
  
  export default ContactForm;
  
const Container = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  height: fit-content;
  width: 100%;
  margin-bottom: 20px;
`;

const ContainerLeft = styled.div`
  margin-top: 50px;
`;

const ContainerRight = styled.div`
  display: flex;
  flex-flow: column;
  height: fit-content;
  width: 100%;
  
`;