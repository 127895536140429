import React from 'react';
import styled from 'styled-components';
import { Servicio_molecule } from '../../molecules/Servicio_molecule';
import image1 from '../../../assets/service/Rectangle 234.png';
import image2 from '../../../assets/service/Rectangle 235.png';
import image3 from '../../../assets/service/Rectangle 236.png';
import image4 from '../../../assets/service/Rectangle 237.png';

export const Service_organisms = () => {
  const text1 = 'Moldeado y pulido de piezas de metal: roscado, taladrado, refrentado, mecanizado, entre otros.';
  const text2 = 'Aplicación de soldadura MIG y de barra para reconstrucción, alargado, soporte, entre otros.';
  const text3 = 'Aplicación de pintura en polvo horneable para recubrimiento de piezas metálicas. Contamos con un horno de 3 mts de ancho x 5 mts de largo.';
  const text4 = 'Mantenimiento general a carretas: cambio de partes torcidas u oxidadas, cambio de ruedas, enderezado de ejes, entre otros.';

  return (
    <ContainerService>
      <Servicio_molecule image={image2} title="Servicio de torno" label={text1} />
      <Servicio_molecule image={image3} title="Soldadura de piezas metalicas" label={text2} />
      <Servicio_molecule image={image4} title="Pintura electrostática" label={text3} />
      <Servicio_molecule image={image1} title="Mantenimiento" label={text4} />
    </ContainerService>
  );
};

const ContainerService = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsivo */
  gap: 20px;
  margin: 40px auto;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 0;
  }
`;
