import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';

type Props = {
    setProperty: React.Dispatch<React.SetStateAction<string>>;
    type: string;
    id: string;
    value: string;
    placeholder?: string;
    setOnFocus: React.Dispatch<React.SetStateAction<string>>;
};

export const Input = ({ setProperty, type, id, value, placeholder, setOnFocus }: Props) => {
  return (
      <InputContent placeholder={placeholder} onFocus={(e)=>setOnFocus('')} id={id} type={type} value={value} aria-placeholder={placeholder} onChange={(e) =>{setProperty(e.target.value)}}>
      </InputContent>
  );
};

const InputContent = styled.input<{}>`
    background-color: #D9D9D9;
    height: 35px;
    width: 100%;
    border: none;
`;