import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Label } from './components/atoms/Label';
import { Category } from './pages/Category';
import { Footer } from './components/organisms/Footer';
import { ButtonHeader } from './components/atoms/ButtonHeader';
import { Us } from './pages/Us';
import { ButtonCatalogue } from './components/atoms/ButtonCatalogue';
import { Catalogue } from './pages/Catalogue';
import { Principal } from './pages/Principal';
import { Header_molecule } from './components/molecules/Header_molecule';
import { Services } from './pages/Services';
import { Contact } from './pages/Contact';

function App() {
  const [selectMenu, setselectMenu] = useState('INICIO');
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        
        
        {/* <ButtonCatalogue textos={['CARRETAS','PORTACANASTAS','PORTACANECAS','PORTACILINDROS','PORTABOMBAS','PORTAZUNCHO','PLATAFORMAS','SOPORTES']}></ButtonCatalogue> */}
        <Header_molecule select={setselectMenu}></Header_molecule>
        {selectMenu==='INICIO' && <Principal></Principal>}
        {selectMenu==='NOSOTROS' && <Us></Us>}
        {selectMenu==='CATEGORÍAS DE PRODUCTOS' && <Category></Category>}
        {selectMenu==='CATALOGO' && <Catalogue></Catalogue>}
        {selectMenu==='SERVICIOS' && <Services></Services>}
        {selectMenu==='CONTÁCTENOS' && <Contact></Contact>}
        
        <Footer></Footer>
      </header>
    </div>
  );
}

export default App;
