import React from 'react';
import styled from 'styled-components';

type Props = {
  image: string;
  title: string;
  label: string;
};

export const Servicio_molecule = ({ image, title, label }: Props) => {
  return (
    <ServicioCard>
      <ServicioImage src={image} alt={title} />
      <ServicioTitle>{title}</ServicioTitle>
      <ServicioLabel>{label}</ServicioLabel>
    </ServicioCard>
  );
};

// Estilos del servicio
const ServicioCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ServicioImage = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-bottom: 15px;
`;

const ServicioTitle = styled.h3`
  font-size: 18px;
  color: #09333F;
  margin-bottom: 10px;
`;

const ServicioLabel = styled.p`
  font-size: 14px;
  color: #333;
`;
