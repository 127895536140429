import React, { useState } from 'react';
import styled from 'styled-components';
import { Label } from '../../atoms/Label';
import { Icons } from '../../atoms/Icons';

type Props = {
  texto?: string;
  image?: string;
  social?: boolean;
  link?: string;
};

export const FooterText = ({ texto, image, social, link }: Props) => {
  return (
    <FooterItem>
      <Icons image={image} socialImage={social} link={link}/>
      {texto && <Label label={texto} colorl={'white'} fontType="400" link={link}/>}
    </FooterItem>
  );
};

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
