import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProductoCategoria } from '../../components/atoms/ProductoCategoria';
import image1 from '../../assets/categories/paginaRecurso15.png'
import image2 from '../../assets/categories/Rectangle48.png'
import image3 from '../../assets/categories/Rectangle62.png'
import image4 from '../../assets/categories/Rectangle63.png'
import image5 from '../../assets/categories/Rectangle78.png'
import image6 from '../../assets/categories/Rectangle83.png'
import image7 from '../../assets/categories/Rectangle91.png'
import image8 from '../../assets/categories/Rectangle98.png'
import image9 from '../../assets/categories/Rectangle102.png'
import image10 from '../../assets/categories/Rectangle110.png'
import image11 from '../../assets/categories/Rectangle115.png'
import image12 from '../../assets/categories/Rectangle119.png'
import { Label } from '../../components/atoms/Label';
import { Producto } from '../../components/atoms/Producto';
import { Catalogue_organisms } from '../../components/organisms/Catalogue_organisms';
import { ImageSlider } from '../../components/atoms/ImageSlider/ImageSlider';

type Props = {

};

export const Catalogue = ({ }: Props) => {
  const textos =[
    'Requieres movilizar cajas?',
    'Requieres movilizar canecas?',
    'Requieres movilizar canastas?',
    'Requieres movilizar cilindros?',
    'Requieres subir o bajar productos por la escalera?',
    'Requieres movilizar bultos?',
    'Requieres movilizar y guardar herramientas?',
    'Requieres levantar la rueda de tu vehículo?',
    'Requieres movilizar plantas eléctricas e hidrolavadoras?',
    'Requieres hacer recolección de basuras?',
    'Requieres alcanzar lugares altos?',
    'Requieres soportes paraplataformas?'
  ];
  return (
    <Container>
      <Label label='Tenemos un producto para cada necesidad' fontType='600' sizeText='32' colorl='#09333F' textAlight='center'/>
      <ContainerSlider>
        <ImageSlider images={[{ src: image1, caption:textos[0] },
          { src: image2, caption:textos[1] }, 
          { src: image3, caption:textos[2] }, 
          { src: image4, caption:textos[3] }, 
          { src: image5, caption:textos[4] },
          { src: image6, caption:textos[5] },
          { src: image7, caption:textos[6] },
          { src: image8, caption:textos[7] },
          { src: image9, caption:textos[8] },
          { src: image10, caption:textos[9] },
          { src: image11, caption:textos[10] },
          { src: image12, caption:textos[11] },
          ]} showDots={false} small='150px'/>
      </ContainerSlider>
      <ContainerLabel>
        <Catalogue_organisms></Catalogue_organisms>
      </ContainerLabel>
    </Container>
      
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin-top: 30px;
    /* Para pantallas medianas como tablets (900px de ancho) */
  @media (max-width: 900px) {
    max-width: 100%; /* Cambia a 2 columnas */
  }

  /* Para pantallas pequeñas como móviles (600px de ancho) */
  @media (max-width: 600px) {
   max-width: 100%; /* Cambia a 1 columna */
  }
`;

const ContainerSlider = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  background-color: #09333F;
  margin: 30px 0;
    /* Para pantallas medianas como tablets (900px de ancho) */
  @media (max-width: 900px) {
    max-width: 91%; /* Cambia a 1 columna */
  }

  /* Para pantallas pequeñas como móviles (600px de ancho) */
  @media (max-width: 600px) {
    max-width: 91%; /* Cambia a 1 columna */
  }
`;

const ContainerLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 26px;
  column-gap: 34px;
  margin-bottom: 50px;
  width: 90%;

  /* Para pantallas medianas como tablets (900px de ancho) */
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); /* Cambia a 2 columnas */
    column-gap: 20px; /* Ajusta el espacio entre columnas */
  }

  /* Para pantallas pequeñas como móviles (600px de ancho) */
  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* Cambia a 1 columna */
    column-gap: 0px; /* Sin espacio entre columnas */
    row-gap: 20px; /* Menos espacio entre filas */
    margin-bottom: 30px; /* Menos margen inferior */
    margin: auto; /* Centrar el contenedor */
  }
`;

const Labels = styled.label`
  font-size: 14px;
`;