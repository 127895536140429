import React from 'react';
import styled from 'styled-components';
import { Label } from '../../atoms/Label';

type Props = {
  title: string;
  image: string;
};

export const IndustrialDesign_molecule = ({ title, image }: Props) => {
  return (
    <DesignCard>
      <ImageService src={image} alt={title} />
      <DesignTitle>{title}</DesignTitle>
    </DesignCard>
  );
};

// Estilos del diseño industrial
const DesignCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ImageService = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-bottom: 15px;
  border: 5px solid #e32520;
  border-radius: 40px;
`;

const DesignTitle = styled.h3`
  font-size: 18px;
  color: #09333F;
  text-align: center;
  margin-top: 10px;
`;
