import React from 'react';
import styled from 'styled-components';

type Props = {
  label?: string;
  sizeText?: string;
  fontType?: string;
  colorl?: string;
  textAlight?: string;
  form?: boolean;
  link?: string;
};

export const Label = ({
  label,
  sizeText,
  fontType,
  colorl,
  textAlight,
  form,
  link
}: Props) => {
  return link ? (
    <StyledLink
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      size={sizeText ?? ''}
      fontType={fontType ?? ''}
      colorl={colorl ?? ''}
      alight={textAlight ?? ''}
    >
      {label}
    </StyledLink>
  ) : (
    <Labels
      forme={form ?? false}
      size={sizeText ?? ''}
      fontType={fontType ?? ''}
      colorl={colorl ?? ''}
      see={label ?? ''}
      alight={textAlight ?? ''}
    >
      {label}
    </Labels>
  );
};


const Labels = styled.label<{forme:boolean; size:string; fontType:string; colorl:string; see:string; alight:string}>`
  font-size:  ${({size}) => size!=''? size+'px' : '16px'};
  font-weight: ${({ fontType }) => fontType!=''? fontType : '500'};
  color: ${({ colorl }) => colorl!=''? colorl : 'black'};
  align-self: center;
  display: ${({ see }) => see!=''? 'flex' : 'none'};
  text-align: ${({ alight }) => alight!=''? alight : 'center'};
  position: ${({ forme }) => forme!=false? 'absolute' : 'none'};
  padding-left: ${({ forme }) => forme!=false? '12px' : 'none'};
`;


const StyledLink = styled.a<{
  size: string;
  fontType: string;
  colorl: string;
  alight: string;
}>`
  font-size: ${({ size }) => (size !== '' ? size + 'px' : '16px')};
  font-weight: ${({ fontType }) => (fontType !== '' ? fontType : '500')};
  color: ${({ colorl }) => (colorl !== '' ? colorl : '#0077cc')};
  text-align: ${({ alight }) => (alight !== '' ? alight : 'center')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
