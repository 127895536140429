import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ImageSliderProps {
    images: {
      src: string;
      caption?: string;
    }[];
    showDots?: boolean;
    small?: string;
  }
  

const ImageSliderWrapper = styled.div<{ small?: string }>`
  min-height: ${({ small }) => small || '300px'};
  padding: 0 10px;

  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .slick-slide img {
    width: 100%;
    height: ${({ small }) => small || '250px'};
    object-fit: cover;
    border-radius: 10px;
    padding: 10px;
  }

  .slick-dots li button:before {
    color: #666;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }
`;

const CaptionText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  text-align: center;
`;

const SlideWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const ImageCaption = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: #fff;
  text-align: center;
`;


export const ImageSlider: React.FC<ImageSliderProps> = ({ images, showDots = true, small }) => {
    const settings = {
      dots: showDots,
      infinite: true,
      speed: 600,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: { slidesToShow: 2 },
        },
        {
          breakpoint: 600,
          settings: { slidesToShow: 1 },
        },
      ],
    };
  
    return (
      <ImageSliderWrapper small={small}>
        <Slider {...settings}>
            {images.map((item, index) => (
            <SlideWrapper key={index}>
                <img src={item.src} alt={`Imagen ${index + 1}`} />
                {item.caption && <ImageCaption>{item.caption}</ImageCaption>}
            </SlideWrapper>
            ))}
        </Slider>
      </ImageSliderWrapper>
    );
  };
  
