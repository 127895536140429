import React, { useEffect } from 'react';
import { useFacebookSDK } from '../../../hooks/useFacebookSDK';
import styled from 'styled-components';

interface FacebookPagePluginProps {
  pageUrl: string;
  width?: string;
  height?: string;
  tabs?: string;
}

export const FacebookPagePlugin: React.FC<FacebookPagePluginProps> = ({
  pageUrl,
  width = '500',
  height = '600',
  tabs = 'timeline',
}) => {
  useFacebookSDK(); // ⬅️ Aquí se carga el SDK si no existe

  useEffect(() => {
    if ((window as any).FB) {
      (window as any).FB.XFBML.parse();
    }
  }, []);

  return (
    <Container
      className="fb-page"
      data-href={pageUrl}
      data-tabs={tabs}
      data-width={width}
      data-height={height}
      data-small-header="true"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote
        cite={pageUrl}
        className="fb-xfbml-parse-ignore"
      >
        <a href={pageUrl}>Facebook</a>
      </blockquote>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;  /* 👈 centra el iframe horizontalmente */
  padding: 0;

  .fb-page {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  iframe {
    max-width: 100% !important;
    margin: 0 auto; /* 👈 asegura centrado */
    display: block;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;


