import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';

type Props = {
  image?: string;
  socialImage?: boolean;
  link?: string;
};

export const Icons = ({ image, socialImage, link }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const content = (
    <ContainerLabel social={socialImage ?? false}>
      <Image src={image} alt="icon" />
    </ContainerLabel>
  );

  return link ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  ) : (
    content
  );
};


const ContainerLabel = styled.div<{social:boolean}>`
  display: flex;
  /* border: ${({ social }) => social?  'solid 2px white': ''}; */
  padding: ${({ social }) => social?  '5px': ''};
`;

const Image = styled.img`
width: 51px;
height: 51px;
`;