import React from 'react';
import styled from 'styled-components';
import image1 from '../../../assets/service/Rectangle 238.png';
import image2 from '../../../assets/service/Rectangle 239.png';
import image3 from '../../../assets/service/Rectangle 240.png';
import image4 from '../../../assets/service/Rectangle 241.png';
import image5 from '../../../assets/service/Rectangle 242.png';
import image6 from '../../../assets/service/Rectangle 243.png';
import { IndustrialDesign_molecule } from '../../molecules/IndustrialDesign_molecule';

export const IndustrialDesign_organisms = () => {
  return (
    <ContainerService>
      <IndustrialDesign_molecule image={image1} title="Trailer" />
      <IndustrialDesign_molecule image={image2} title="Valvatec" />
      <IndustrialDesign_molecule image={image3} title="Portaneveras" />
      <IndustrialDesign_molecule image={image4} title="Portacesped" />
      <IndustrialDesign_molecule image={image5} title="Gabinete químicos" />
      <IndustrialDesign_molecule image={image6} title="Portazuncho doble carrete" />
    </ContainerService>
  );
};

const ContainerService = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Responsivo */
  gap: 20px;
  margin: 40px auto;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 0;
  }
`;
