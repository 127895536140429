import globalStyles from '../../../globalStyles.module.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//carretas
import image from '../../../assets/asdRecurso3-100.jpg';
import image3 from '../../../assets/linea/Recurso7.png';
import image4 from '../../../assets/linea/Recurso8.png';
import image5 from '../../../assets/linea/Recurso9.png';
import image6 from '../../../assets/linea/Recurso10.png';
import image7 from '../../../assets/linea/Recurso13.png';
import image8 from '../../../assets/linea/Recurso14.png';
//portacanastas
import image2 from '../../../assets/linea/Recurso15.png';
import image9 from '../../../assets/linea/Recurso16.png';
import image10 from '../../../assets/linea/Recurso17.png';
import image11 from '../../../assets/linea/Recurso18.png';
import image12 from '../../../assets/linea/Recurso19.png';
import image13 from '../../../assets/linea/Recurso20.png';
import image14 from '../../../assets/linea/Recurso21.png';
//PORTACANECAS
import image15 from '../../../assets/linea/Recurso22.png';
import image16 from '../../../assets/linea/Recurso23.png';
import image17 from '../../../assets/linea/Recurso24.png';
import image18 from '../../../assets/linea/Recurso25.png';
import image19 from '../../../assets/linea/Recurso26.png';
import image20 from '../../../assets/linea/Recurso27.png';
import image21 from '../../../assets/linea/Recurso28.png';
import image22 from '../../../assets/linea/Recurso29.png';
import image23 from '../../../assets/linea/Recurso30.png';
//PORTACILINDROS
import image24 from '../../../assets/linea/Recurso31.png';
import image25 from '../../../assets/linea/Recurso32.png';
import image26 from '../../../assets/linea/Recurso33.png';
import image27 from '../../../assets/linea/Recurso34.png';
import image28 from '../../../assets/linea/Recurso35.png';
import image29 from '../../../assets/linea/Recurso36.png';
import image30 from '../../../assets/linea/Recurso37.png';
import image31 from '../../../assets/linea/Recurso38.png';
import image32 from '../../../assets/linea/Recurso39.png';
import image33 from '../../../assets/linea/Recurso40.png';
//PORTABOMBAS
import image34 from '../../../assets/linea/Recurso41.png';
import image35 from '../../../assets/linea/Recurso42.png';
//PORTAZUNCHO
import image36 from '../../../assets/linea/Recurso43.png';
//PLATAFORMAS
import image37 from '../../../assets/linea/Recurso44.png';
//SOPORTES
import image38 from '../../../assets/linea/Recurso45.png';
import image39 from '../../../assets/linea/Recurso46.png';
import image40 from '../../../assets/linea/Recurso47.png';
//ESPECIALES
import image41 from '../../../assets/especiales/especialesRecurso3.png';
import image42 from '../../../assets/especiales/especialesRecurso5.png';
import image43 from '../../../assets/especiales/especialesRecurso6.png';
import image44 from '../../../assets/especiales/especialesRecurso6.png';
import image45 from '../../../assets/especiales/especialesRecurso7.png';
import image46 from '../../../assets/especiales/especialesRecurso8.png';
import image47 from '../../../assets/especiales/especialesRecurso9.png';
import image48 from '../../../assets/especiales/especialesRecurso10.png';
import image49 from '../../../assets/especiales/especialesRecurso11.png';
import image50 from '../../../assets/especiales/especialesRecurso12.png';
import image51 from '../../../assets/especiales/especialesRecurso13.png';
import image52 from '../../../assets/especiales/especialesRecurso14.png';
import image53 from '../../../assets/especiales/especialesRecurso15.png';
import image54 from '../../../assets/especiales/especialesRecurso16.png';
import image55 from '../../../assets/especiales/especialesRecurso17.png';
import image56 from '../../../assets/especiales/especialesRecurso3.png';
import image57 from '../../../assets/especiales/especialesRecurso3.png';
import image58 from '../../../assets/especiales/especialesRecurso3.png';
import image59 from '../../../assets/especiales/especialesRecurso3.png';
import image60 from '../../../assets/especiales/especialesRecurso3.png';
import image61 from '../../../assets/especiales/especialesRecurso3.png';
import image62 from '../../../assets/especiales/especialesRecurso3.png';
import image63 from '../../../assets/especiales/especialesRecurso3.png';
import image64 from '../../../assets/especiales/especialesRecurso3.png';
import image65 from '../../../assets/especiales/especialesRecurso3.png';

import { Label } from '../../atoms/Label';
import { Producto } from '../../atoms/Producto';
import { ProductoDetail } from '../../atoms/ProductoDetail';
import json from '../../../assets/productos.json'

type Props = {
/* categorySelect: (e:any) => void; */
categorySelect:string;
open: React.Dispatch<React.SetStateAction<boolean>>;
val: boolean;
};

export const Catalogue_molecule = ({ categorySelect, open, val }: Props) => {
  /* const [openProduct, setopenProduct] = useState(val); */
  const [product, setproduct] = useState(json.data.filter(element => element.name==='TC 01 CARRETA EN TUBO - TIPO PESADO'));
  const [isDetailView, setIsDetailView] = useState(false);

  const handleProductClick = (productData: any) => {
    setproduct(productData);
    setIsDetailView(true);
  };

  const handleBack = () => {
    setIsDetailView(false);
  };

  return (
    <div style={{marginTop:'32px'}}>
      <ContainerLabel show={categorySelect==='CARRETAS'&&val!=true?true:false}>
        <Producto image={image} texto='TC 01 CARRETA EN TUBO - TIPO PESADO' option={setproduct} open={open}></Producto>
        <Producto image={image3} texto='TC 02 CARRETA EN ÁNGULO DE 1-1/2”' option={setproduct} open={open}></Producto>
        <Producto image={image4} texto='TC 03 CARRETA EN ÁNGULO DE 2”' option={setproduct} open={open}></Producto>
        <Producto image={image5} texto='TC 04 CARRETA EN TUBO TIPO LIVIANO, REFRESQUERA' option={setproduct} open={open}></Producto>
        <Producto image={image6} texto='TC 05 CARRETA EN TUBO CON SISTEMA TRIAL' option={setproduct} open={open}></Producto>
        <Producto image={image7} texto='TC 06 SISTEMA TRIAL' option={setproduct} open={open}></Producto>
        <Producto image={image8} texto='TC 07 CARRETA EN TUBO CON ABATIBLE TIPO PESADO' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='PORTACANASTAS'&&val!=true?true:false}>
        <Producto image={image2} texto='TC 08 CARRETA EN TUBO DOBLE SERVICIO PARA CAJA' option={setproduct} open={open}></Producto>
        <Producto image={image9} texto='TC 09 CARRETA EN TUBO  DOBLE SERVICIO PLEGABLE' option={setproduct} open={open}></Producto>
        <Producto image={image10} texto='TC 10 CARRETA EN TUBO  PLEGABLE' option={setproduct} open={open}></Producto>
        <Producto image={image11} texto='TC 11 CARRETA PORTACANASTA CON BASE REFORZADA...' option={setproduct} open={open}></Producto>
        <Producto image={image12} texto='TC 12 PORTACANASTA PARA SUPERMERCADO CON TIRO...' option={setproduct} open={open}></Producto>
        <Producto image={image13} texto='TC 13 CARRETA DOBLE NIVEL PARA CANASTA DE CERVEZA' option={setproduct} open={open}></Producto>
        <Producto image={image14} texto='TC 14 CARRETA DOBLE NIVEL PLEGABLE' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='PORTACANECAS'&&val!=true?true:false}>
        <Producto image={image15} texto='TC 15 PORTACANECAS VERTICAL DOLLY' option={setproduct} open={open}></Producto>
        <Producto image={image16} texto='TC 16 PORTACANECAS VERTICAL DOLLY CON ACCESO...' option={setproduct} open={open}></Producto>
        <Producto image={image17} texto='TC 17 PORTACANECAS VERTICAL DOLLY CON ACCESO...' option={setproduct} open={open}></Producto>
        <Producto image={image18} texto='TC 18 PORTACANECA DE 3 RUEDAS PARA CANECA DE 55 GL' option={setproduct} open={open}></Producto>
        <Producto image={image19} texto='TC 19 PORTACANECAS DE 4 RUEDAS (CACHONA)' option={setproduct} open={open}></Producto>
        <Producto image={image20} texto='TC 20 PORTACANECAS DE 5 RUEDAS (CACHONA)' option={setproduct} open={open}></Producto>
        <Producto image={image21} texto='TC 21 PORTACANECA ESTACIONARIO HORIZONTAL (BURRO)' option={setproduct} open={open}></Producto>
        <Producto image={image22} texto='TC 22 PORTACANECAS VOLTEACANECAS DE 6 RUEDAS' option={setproduct} open={open}></Producto>
        <Producto image={image23} texto='TC 23 CARRETA PORTACANECA PLÁSTICA O METÁLICA' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='PORTACILINDROS'&&val!=true?true:false}>
        <Producto image={image24} texto='TC 24 PORTACILINDRO SENCILLO - BARANDA CERRADA' option={setproduct} open={open}></Producto>
        <Producto image={image25} texto='TC 25 PORTACILINDRO SENCILLO GAS PROPANO DE 40 LBS' option={setproduct} open={open}></Producto>
        <Producto image={image26} texto='TC 26 PORTACILINDRO SENCILLO GAS PROPANO DE 100 LBS' option={setproduct} open={open}></Producto>
        <Producto image={image27} texto='TC 27 PORTACILINDRO DOBLE BARANDA CERRADA' option={setproduct} open={open}></Producto>
        <Producto image={image28} texto='TC 28 PORTACILINDRO SENCILLO - BARANDA ABIERTA' option={setproduct} open={open}></Producto>
        <Producto image={image29} texto='TC 29 PORTACILINDRO DOBLE BARANDA ABIERTA' option={setproduct} open={open}></Producto>
        <Producto image={image30} texto='TC 30 PORTACILINDRO SENCILLO DE 3 RUEDAS' option={setproduct} open={open}></Producto>
        <Producto image={image31} texto='TC 31 PORTACILINDRO DOBLE DE 3 RUEDAS' option={setproduct} open={open}></Producto>
        <Producto image={image32} texto='TC 32 PORTACILINDRO SENCILLO DE 4 RUEDAS' option={setproduct} open={open}></Producto>
        <Producto image={image33} texto='TC 33 PORTACILINDRO DOBLE DE 4 RUEDAS' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='PORTABOMBAS'&&val!=true?true:false}>
        <Producto image={image34} texto='TC 34 TRAILER MANUAL CON TIRO REMOVIBLE PORTABOMBA' option={setproduct} open={open}></Producto>
        <Producto image={image35} texto='TC 35 TRAILER MANUAL EN ÁNGULO DE 2”' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='PORTAZUNCHO'&&val!=true?true:false}>
        <Producto image={image36} texto='TC 36 CARRETA EN TUBO PORTAZUNCHO PLASTICO CON CAJA' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='PLATAFORMAS'&&val!=true?true:false}>
        <Producto image={image37} texto='TC 37 PLATAFORMAS EN LAMINA CORRUGADA DE ACERO' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='SOPORTES'&&val!=true?true:false}>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={categorySelect==='ESPECIALES'&&val!=true?true:false}>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
        <Producto image={image38} texto='TC 38 SOPORTE FIJO EXTRAPESADO SERIE 54' option={setproduct} open={open}></Producto>
        <Producto image={image39} texto='TC 39 SOPORTE GIRATORIO EXTRAPESADO SERIE 55' option={setproduct} open={open}></Producto>
        <Producto image={image40} texto='TC 40 FRENOS TIPO MARIPOSA' option={setproduct} open={open}></Producto>
      </ContainerLabel>
      <ContainerLabel show={val}>
        <ProductoDetail texto={product}></ProductoDetail>
      </ContainerLabel>
    </div>
  );
};

const ContainerLabel = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show !== true ? 'none' : 'grid')};
  grid-template-columns: repeat(4, 1fr);
  row-gap: 8px;
  column-gap: 10px;
  margin-top: 26px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* 3 columnas en pantallas medianas */
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en tablets */
    column-gap: 20px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr); /* 1 columna en móviles */
    row-gap: 15px;
    column-gap: 0px;
    margin-bottom: 30px;
  }
`;

const Labels = styled.label`
  font-size: 14px;
  @media (max-width: 600px) {
    font-size: 12px; /* Ajusta el tamaño de fuente en móviles */
  }
`;