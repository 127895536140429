import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ProductoCategoria } from '../../components/atoms/ProductoCategoria';
import image from '../../assets/paginaRecurso14.png';
import image2 from '../../assets/Rectangle250.png';
import image3 from '../../assets/Rectangle251.png';
import image4 from '../../assets/Rectangle17.png';
import image5 from '../../assets/Rectangle19.png';
import image6 from '../../assets/Rectangle252.png';
import { Label } from '../../components/atoms/Label';
import { ImageSlider } from '../../components/atoms/ImageSlider/ImageSlider';

type Props = {};

export const Us = ({ }: Props) => {
    const Onep = 'Me permito presentar a ustedes a CARRETILLAS TECNICAS DE COLOMBIA S.A.S. - TECNICARRETILLAS S.A.S. -, somos una empresa con amplia experiencia en el sector industrial. Nos hemos consolidado con la reglamentación legal, fabricando productos con diseños, planos o muestra física de partes o equipos completos y reconstrucción y mantenimiento de los mismos, brindando soluciones integrales a través de la fabricación y comercialización de productos y servicios destinados a satisfacer las diferentes necesidades de nuestros clientes.';
    const Twop = 'Fabricamos todo tipo de carretas para movilización de paquetes, cajas, cilindros, tambores plásticos o metálicos en todos los tamaños y formas, tráiler para transporte de motobombas y moto soldadores en diversas referencias; además bandas transportadoras, estaciones y transportadores de rodillos, o de acuerdo a la movilización de su producto o necesidad industrial. Nuestros productos son fabricados para satisfacer sus necesidades de movilización de productos con ánimo de servir en el desarrollo de su actividad; para así establecer con usted y sus colaboradores una relación comercial en el momento y fecha que usted estime conveniente. Para el desarrollo de nuestra labor contamos con personal altamente calificado y maquinaria adecuada, lo cual garantiza la efectividad, puntualidad, calidad, atención personalizada y un precio adecuado en cada uno de nuestros productos y servicios.';
    const Threep = 'Somos una empresa dedicaca a la fabricación de productos para movilización de carga manual, con amplia experiencia en el sector, produciendo sobre diseños, planos o muestras físicas, así como a la reparación de los mismos. Nuestro propósito va enficado a la satisfacción de nuestros clientes, a través de la optimización de los procesos y recursos para la obtención de productos de excelente calidad.';
    const Fourp = 'Ser reconocidos a nivel nacional como una de las mayores empresas fabricantes de productos para movilización de carga manual de todo tipo, con altos estandares de calidad, ergonomía y eficacia, alcanzando y manteniendo un liderazgo en el mercado.';

  return (
    <>
      <ContainerUs>
      {/* Sección Quienes somos */}
      <IntroSection>
        <BackgroundTitle>
          <Label label="Quienes somos?" fontType="600" sizeText="32" colorl="#fff" />
        </BackgroundTitle>
        <TextBlock>
          <Label label={Onep} fontType="400" sizeText="16" colorl="#333" textAlight='left'/>
        </TextBlock>
      </IntroSection>

      {/* Sección Misión y Visión */}
      <BlueBlock>
        <MissionVision>
          <div>
            <Label label="Nuestra Misión" fontType="600" sizeText="22" colorl="#fff" />
            <Label label={Threep} fontType="400" sizeText="16" colorl="#fff" textAlight='left' />
          </div>
          <div>
            <Label label="Nuestra Visión" fontType="600" sizeText="22" colorl="#fff" />
            <Label label={Fourp} fontType="400" sizeText="16" colorl="#fff" textAlight='left' />
          </div>
        </MissionVision>
      </BlueBlock>

      {/* Texto adicional */}
      <TextBlock>
        <Label label={Twop} fontType="400" sizeText="16" colorl="#333" textAlight='left' />
      </TextBlock>

      {/* Galería final */}
      <ImageSlider images={[{ src: image }, { src: image3 }, { src: image4 }, { src: image5 }, { src: image6 }]}/>
    </ContainerUs>
    </>
  );
};

// Estilos mejorados
const ContainerUs = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
  /* background-color: #f5f7fa; */

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 80%;
  }
`;

const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BackgroundTitle = styled.div`
  background-image: url(${image2});
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
  border-radius: 10px;
`;

const TextBlock = styled.div`
  max-width: 800px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  /* box-shadow: 0 2px 6px rgba(0,0,0,0.1); */
  align-self: center;
`;

const BlueBlock = styled.div`
  background-color: #083f4c;
  padding: 50px 30px;
  border-radius: 10px;
  color: white;
`;

const MissionVision = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  & > div {
    flex: 1;
    min-width: 280px;
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
`;

