import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  textos: string[];
  select: React.Dispatch<React.SetStateAction<string>>;
};

export const ButtonHeader = ({ textos, select }: Props) => {
  const [active, setActive] = useState(textos[0]);
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar el menú hamburguesa

  useEffect(() => {
    select(active);
  }, [active, select]);

  return (
    <>
      <HamburgerIcon onClick={() => setIsOpen(!isOpen)}>
        &#9776;
      </HamburgerIcon>
      <ContainerHeader isOpen={isOpen}>
        {textos.map((type) => (
          <ButtonToggle 
            actives={active === type} 
            onClick={() => {
              setActive(type);
              setIsOpen(false); // Cierra el menú cuando seleccionas una opción
            }} 
            key={type}
          >
            {type}
          </ButtonToggle>
        ))}
      </ContainerHeader>
    </>
  );
};

// Estilos para los botones y el menú hamburguesa
const ContainerHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  background-color: transparent;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #09333F;
    width: 100%;
    height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    z-index: 2;
  }
`;

const ButtonToggle = styled.button<{ actives: boolean }>`
  background-color: ${({ actives }) => (actives ? 'white' : '#09333F')};
  color: ${({ actives }) => (actives ? '#09333F' : 'white')};
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: ${({ actives }) => (actives ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};

  &:hover {
    background-color: white;
    color: #09333F;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
`;

// Menú hamburguesa
const HamburgerIcon = styled.div`
  display: none;
  font-size: 30px;
  color: white;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;
