import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface SliderProps {
    images: {
      src: string;
      caption?: string;
    }[];
    showDots?: boolean;
    small?: string;
  }
  

  const SliderWrapper = styled.div<{ small?: string }>`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .slick-slide img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    padding: 10px;
  }

  .slick-dots li button:before {
    color: #666;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding: 0 10px;

    .slick-slide img {
      height: ${({ small }) => small ? `calc(${small} * 0.8)` : '200px'};
      padding: 5px;
    }
  }
`;


const CaptionText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  text-align: center;
`;

const SlideWrapper = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const ImageCaption = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  max-width: 90%;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 12px;
    bottom: 8px;
    left: 8px;
    padding: 4px 8px;
  }
`;


const ArrowButtonR = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  right: '130px'

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
    font-size: 18px;
    right: '10px'
  }
`;

const ArrowButtonL = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  left: '130px'

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
    font-size: 18px;
    left: '10px'
  }
`;

export const SliderP: React.FC<SliderProps> = ({ images, showDots = true, small }) => {

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <ArrowButtonL className={className} style={{ ...style  }} onClick={onClick}>
        ‹
      </ArrowButtonL>
    );
  };
  
  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <ArrowButtonR className={className} style={{ ...style,  }} onClick={onClick}>
        ›
      </ArrowButtonR>
    );
  };

    const settings = {
      
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      dots: showDots,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: { slidesToShow: 2 },
        },
        {
          breakpoint: 600,
          settings: { slidesToShow: 1 },
        },
      ],
    };
  
    return (
      <SliderWrapper small={small}>
        <Slider {...settings}>
          {images.map((item, index) => (
            <SlideWrapper key={index}>
              <img src={item.src} alt={`Imagen ${index + 1}`} />
              {item.caption && <ImageCaption>{item.caption}</ImageCaption>}
            </SlideWrapper>
          ))}
        </Slider>
      </SliderWrapper>
    );
  };
  
