import globalStyles from '../../../globalStyles.module.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import image from '../../assets/paginaRecurso15.png'
import { Catalogue_molecule } from '../../molecules/Catalogue_molecule';
import { ButtonCatalogue } from '../../atoms/ButtonCatalogue';
import { ButtonBack } from '../../atoms/ButtonBack';
import { ButtonHeader } from '../../atoms/ButtonHeader';
import { Button } from '../../atoms/Button';

type Props = {
/* categorySelect: (e:any) => void; */
};

export const Catalogue_organisms = ({ }: Props) => {
  const [category, setcategory] = useState('CARRETAS');
  const [line, setline] = useState('Productos de línea');
  const [openProduct, setopenProduct] = useState(false);

  useEffect(() => {
    if(line==='Productos especiales')
    setcategory('ESPECIALES');
  });

  return (
      <ContainerLabel>
        <ContainerOption>
            <Button texto='Productos de línea' active={line==='Productos de línea'?true:false} selectText={setline}></Button>
            <ContainerSubMenu show={line==='Productos de línea'?true:false}>
                <ButtonCatalogue textos={['CARRETAS','PORTACANASTAS','PORTACANECAS','PORTACILINDROS','PORTABOMBAS','PORTAZUNCHO','PLATAFORMAS','SOPORTES']} selectText={setcategory}></ButtonCatalogue>
            </ContainerSubMenu>
            {/* <Button texto='Productos especiales' active={line==='Productos especiales'?true:false} selectText={setline}></Button> */}
            <ButtonBack open={setopenProduct} show={openProduct}></ButtonBack>
        </ContainerOption>
        <ContainerProduct>
            <Catalogue_molecule categorySelect={category} open={setopenProduct} val={openProduct}></Catalogue_molecule>
        </ContainerProduct>
      </ContainerLabel>
  );
};

const ContainerLabel = styled.div<{}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 26px;
  column-gap: 34px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr; /* Una columna en pantallas más pequeñas */
  }
`;

const Labels = styled.label`
  font-size: 14px;
`;

const ContainerOption = styled.div<{}>`
  display: grid;
  height: fit-content;
  grid-row-gap: 24px;

  @media (max-width: 900px) {
    grid-row-gap: 16px; /* Ajustar espacio entre elementos en pantallas más pequeñas */
  }

  @media (max-width: 600px) {
    padding: 10px 0; /* Añadir padding para móviles */
  }
`;

const ContainerProduct = styled.div<{}>`
  display: grid;

  @media (max-width: 900px) {
    padding: 0; /* Ajustar el padding en pantallas pequeñas */
  }
`;

const ContainerSubMenu = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'grid' : 'none')};

  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* Menu en una columna en móvil */
    gap: 10px;
  }
`;
