import { useEffect } from 'react';

export const useFacebookSDK = () => {
  useEffect(() => {
    // Evita cargarlo más de una vez
    if (document.getElementById('facebook-jssdk')) return;

    const script = document.createElement('script');
    script.id = 'facebook-jssdk';
    script.src = 'https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v17.0';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    const fbRoot = document.createElement('div');
    fbRoot.id = 'fb-root';
    document.body.prepend(fbRoot);
  }, []);
};
