import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import arrow from '../../../assets/Arrow1.png'

type Props = {
    open: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
};

export const ButtonBack = ({ open, show }: Props) => {
  return (
      <ButtonToggle show={show} onClick={() => open(false)}><img src={arrow}/>
      </ButtonToggle>
  );
};

const ButtonToggle = styled.button<{show:boolean}>`
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background-color: #09333F;
    border: none;
    justify-self: center;
    display: ${({ show }) => show!=false? 'block' : 'none'};
`;