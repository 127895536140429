import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import image from '../../../assets/Rectangle1.png'; // Imagen del logo
import { ButtonHeader } from '../../atoms/ButtonHeader';

type Props = {
    select: React.Dispatch<React.SetStateAction<string>>;
};

export const Header_molecule = ({ select }: Props) => {
  return (
    <HeaderContainer>
      <ContentWrapper>
        <LogoSection>
          <LogoImage src={image} alt="Logo de la empresa"/>
        </LogoSection>
        <NavSection>
          <ButtonHeader textos={['INICIO','NOSOTROS','CATALOGO','SERVICIOS','CONTÁCTENOS']} select={select}/>{/* 'INICIO','NOSOTROS','CATEGORÍAS DE PRODUCTOS','CATALOGO','SERVICIOS','CONTÁCTENOS' */}
        </NavSection>
      </ContentWrapper> 
    </HeaderContainer>
  );
};

// Estilos responsivos para el header
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  background-color: #09333F;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px 0;
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px; /* Espacio lateral */

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0; /* Espacio lateral */
  }
`;


const LogoSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 15px;
  }
`;

const NavSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const LogoImage = styled.img`
  height: 60px;
  max-width: 200px;
  object-fit: contain;
  border-radius: 8px;

  @media (max-width: 768px) {
    height: 50px;
  }
`;
