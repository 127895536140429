import globalStyles from '../../../globalStyles.module.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Label } from '../Label';
import { log } from 'console';
import colors from '../../../assets/Recurso5.png';
import background from '../../../assets/Recurso3.png';

type Props = {
  texto: {
    name: string;
    image: string;
    specifications: string;
    dimension: string;
    uses: string;
}[];
};


export const ProductoDetail = ({ texto }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  

  return (
    <>
      <ContainerLabel>
        <ContainerTitle>
          <Label label={texto[0].name} sizeText='20' fontType='500' textAlight='left'/>
        </ContainerTitle>
        <ContainerImage>
          <Imagen src={process.env.PUBLIC_URL + "/imagenes/" +texto[0].image}></Imagen>
        </ContainerImage> 
        <ContainerData>
          <ContainerEsp>
            <Label label='ESPECIFICACIONES' sizeText='12' fontType='700' textAlight='left'/>
            <Label label={texto[0].specifications} sizeText='12' fontType='700' textAlight='left'/>
          </ContainerEsp>
          <ContainerDim>
            <Label label={'DIMENSIONES: '+texto[0].dimension} sizeText='12' fontType='700' textAlight='left'/>
          </ContainerDim>
          <ContainerUses>
            <Label label={'USOS: '+texto[0].uses} sizeText='12' fontType='700' textAlight='left'/>
          </ContainerUses>
          <ContainerColors>
            <Label label={'Colores disponibles'} sizeText='12' fontType='700' textAlight='left'/>
            <ImageColor src={colors}/>
          </ContainerColors>
        </ContainerData>
      </ContainerLabel>
    </>
  );
};

const ContainerLabel = styled.div`
    display: grid;
    grid-template-areas:
        "a a"
        "b c";
    /* background-image: url(${background}); */
    padding: 25px;
    min-width: 692px;
    /* background-size: 100%; */

    /* Estilo responsivo */
    @media (max-width: 768px) {
    width: auto; /* Adaptable para mobile */
    grid-template-areas:
        "a"
        "b"
        "c";
    font-size: 16px;
    background-size: auto;
    min-width: auto;
    padding: 0;
    }
`;

const Imagen = styled.img`
  width: 100%;
`;

const ContainerData = styled.div`
  display: flex;
  flex-flow: column;
  /* height: 203px; */
  background: rgb(217, 217, 217);
  padding: 5px;
  grid-area: c;
  width: 300px;
  background: white;
  border: 5px solid red;
  border-radius: 50px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(178, 177, 177, 1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(178, 177, 177, 1);
  box-shadow: 10px 10px 5px 0px rgba(178, 177, 177, 1);
`;

const ContainerEsp = styled.div`
  margin-top: 45px;
  margin-left: 31px;
  margin-right: 31px;
`;

const ContainerDim = styled.div`
  margin-top: 19px;
  margin-left: 31px;
  margin-right: 31px;
`;

const ContainerUses = styled.div`
  margin-top: 7px;
  margin-left: 31px;
  margin-right: 31px;
`;

const ContainerTitle = styled.div`
  /* margin-left: 39px; */
  margin-bottom: 21px;
  display: grid;
`;
const ContainerImage = styled.div`
  grid-area: b;
  /* margin-left: 39px; */
  display: grid;
  max-width: 330px;
`;
const ContainerColors = styled.div`
  margin-top: 17px;
  margin-left: 31px;
  margin-right: 31px;
  display: grid;
`;

const ImageColor = styled.img`

`;