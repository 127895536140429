import globalStyles from '../../../globalStyles.module.scss';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Label } from '../Label';

type Props = {
    image?: string;
    texto?: string;
};

export const ProductoCategoria = ({ image, texto }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <ContainerLabel actives={texto != null?true:false}>
        <Imagen actives={texto != null?false:true} src={image}></Imagen>
        <Label fontType='400' label={texto} sizeText='15'/>
      </ContainerLabel>
    </>
  );
};

const ContainerLabel = styled.div<{actives:boolean}>`
  display: grid;
  width: 198px;
  height: 180px;
  ${({ actives }) => actives && `border: 1px solid #0068A1; `};
  padding: 16px;
`;

const Imagen = styled.img<{actives:boolean}>`
width: 100%;
magin=bottom: 16px;
${({ actives }) => actives && `margin: auto; `};
`;