import globalStyles from '../../../globalStyles.module.scss';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  textos: string[];
  selectText: React.Dispatch<React.SetStateAction<string>>;
};

export const ButtonCatalogue = ({ textos, selectText }: Props) => {
  const [active, setActive] = useState(textos[0]);

  return (
    <ContainerCatalogue>
    {textos.map((type) => (
      <ButtonToggleCatalogue actives={active === type?type:''} onClick={() => {setActive(type); selectText(type)}} key={type}>
        {type}
      </ButtonToggleCatalogue>
    ))}
    </ContainerCatalogue>
  );
};

const ContainerCatalogue = styled.div`
  display: flex;
  flex-flow: column;
  /* height: 64px; */
  background-color: #DC2E31;
  /* padding-left: 25px; */
  /* padding-right: 25px; */
  height: fit-content;

`;

const ButtonToggleCatalogue = styled.button<{actives:string}>`
  opacity: 0.7;
  ${({ actives }) => actives && `opacity: 1; `};
  
  background-color: #DC2E31;
  color: white;
  /* padding: 5px 15px; */
  /* border-radius: 5px; */
  /* outline: 0; */
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  /* box-shadow: 0px 2px 2px lightgray; */
  transition: ease background-color 250ms;
  font-weight: 700;
  &:hover {
    background-color: white;
    color: #DC2E31;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
  border: none;
  height: 40px;
  /* align-self: center; */
  /* max-width: 130px; */
  margin: 5px;
  text-align: start;
  ${({ actives }) => actives && `color: #DC2E31; `};
  ${({ actives }) => actives && `background-color: white; `};
`;